import Vue from 'vue';

import '@/components/Admin/DashboardLayout/DashboardLayout.css';
import Login from '@/components/Shared/Login/Login';

const ClientLayout = Vue.extend({
  render() {
    if (!this.$store.state.user) {
      return (
        <div class="login-container">
          <Login />
        </div>
      );
    }


    return (
      <transition>
        <router-view></router-view>
      </transition>
    );
  },
});

export default ClientLayout;
